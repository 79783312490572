/* eslint-disable react-perf/jsx-no-new-object-as-prop */
// import { Fragment, ReactNode } from 'react';
// import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Box } from '@mui/joy';

type ModalProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  icon?: string;
  title?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
};

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.surface',
  border: '2px solid',
  boxShadow: 24,
  p: 4,
  borderRadius: 'sm',
};

export function Modals({
  children,
  isOpen,
  title,
  content,
  icon,
  footer,
  onClose = () => {},
}: ModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <ModalClose asChild>
          <Button variant="soft" size="sm" sx={{ position: 'absolute', top: 8, right: 8 }}>
            <XMarkIcon />
          </Button>
        </ModalClose>

        {icon && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Image src={`/icons/${icon}.svg`} alt="" width={200} height={200} layout="fixed" />
          </Box>
        )}

        {title && (
          <Typography
            level="h2"
            id="modal-title"
            sx={{ mt: 2, textAlign: 'center', color: 'text.primary' }}
          >
            {title}
          </Typography>
        )}

        {content && (
          <Typography
            id="modal-description"
            sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}
          >
            {content}
          </Typography>
        )}

        {children}

        {footer && (
          <Sheet
            sx={{
              mt: 2,
              pt: 2,
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            {footer}
          </Sheet>
        )}
      </Box>
    </Modal>
  );
}
